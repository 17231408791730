<template>
  <!--门店预订单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import LD from "lodash";
import {
  listOrder as List,
  updateOrderStatus as UpStatus,
  copyShopPreOrder,
  listSummary,
} from "@/api/shop/bill/reserveOrder"; //门店预订单

export default {
  name: "reserveOrder",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      dialogOptions: {},
      options: {
        uuid: "ad0674b7-54ec-23c5-6e89-b4378c0e5e9e",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        exportOption: {
          show: true,
          exportApi: "exportOrderList",
          exportName: "预订单门店表",
          timeout: 60 * 5 * 1880,
        },
        title: "门店预订单列表",
        getListApi: List,
        getSummaryApi: listSummary,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "date",
            label: "取货日期",
            model: "",
            filter: "arrivalDate",
            seniorSearch: true,
          },
          {
            type: "local",
            label: "状态",
            model: "",
            filter: "orderStatuss",
            seniorSearch: true,
            option: {
              data: [
                { label: "全部", value: "1" },
                { label: "未接单", value: "2" },
                { label: "待配送", value: "3" },
                { label: "待自提", value: "4" },
                { label: "已取货", value: "5" },
                { label: "已完成", value: "6" },
                { label: "待退款", value: "10" },
                { label: "已退货", value: "7" },
                { label: "已拒单", value: "9" },
              ],
              value: "value",
              label: "label",
              multiple: true,
            },
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "orderShopIds",
              seniorSearch: true,
              label: "预定门店",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "pickUpStoreIds",
              seniorSearch: true,
              label: "取货门店",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "fetch_type",
            option: {
              filter: "fetchTypes",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中方案吗？",
            type: "success",
            icon: "el-icon-document",
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            alertText: "确认要删除选中方案吗？",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "stop",
            label: "终止",
            alertText: "确认要终止选中方案吗？",
            icon: "el-icon-remove-outline",
            type: "primary",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "export",
            right: true,
            label: "导出",
            icon: "el-icon-upload2",
            type: "",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "orderStatusName",
            label: "单据状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderQty",
            label: "单据数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderPayMoney",
            label: "单据金额(元)",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderShopName",
            label: "预定门店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "deliveryShopName",
            label: "取货/配送门店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "receiverName",
            label: "客户名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "receiverPhone",
            label: "联系电话",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "fetchTypeName",
            label: "取货方式",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产部门",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建日期",
            minWidth: 120,
            align: "center",
          },
        ],
        summary: ["orderPayMoney"],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const actionMap = {
        add: () => this.linkEvent("ReserveOrderDetail"),
        update: (rowData) =>
          this.linkEvent("ReserveOrderDetail", {
            billId: rowData.billId,
            type: "Update",
          }),
        audit: () => this.setionEvent("审核", 2),
        del: () => this.setionEvent("删除", 3),
        stop: () => this.setionEvent("终止", 4),
      };
      switch (typeof actionMap[type]) {
        case "function":
          actionMap[type](row);
          break;
        case "copy":
          {
            const billIds = this.options.check.map((i) => i.billId);
            copyShopPreOrder(billIds).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          console.log("---");
      }
    },
    async setionEvent(type, status) {
      let billIds = LD.map(this.options.check, "billId");
      if (billIds.length >= 1) {
        try {
          await UpStatus({
            billIds,
            billStatus: status,
          });
          this.$message.success(`${type}成功`);
          this.$refs.tablePage.getList();
        } catch (err) {}
      } else {
        this.$message.success("请选择需要执行的数据");
      }
    },
    linkEvent(name, obj = {}) {
      this.$router.push({
        name,
        query: obj,
      });
    },
  },
};
</script>
